<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mhome-refund">
				<div class="refund-section">
					<div class="title">{{$t('mobile.refund.goods')}}</div>
					<div class="item-goods" v-for="(item,index) in goodsInfo.order_goods" :key="index">
						<img class="item-pic" :src="item.goods_image" alt="">
						<div class="item-main">
							<div class="name">{{item.goods_name}}</div>
							<div class="desc">{{item.specification}}</div>
						</div>
						<div class="item-box">
							<div class="price">￥<span>{{item.price}}</span></div>
							<div class="number">x{{item.quantity}}</div>
						</div>
					</div>
					
				</div>
				<div class="refund-section">
					<div class="title">{{$t('mobile.refund.selectService')}}</div>
					<router-link :to="{ name: 'mRefundApply', params: $route.params }" class="item-row" replace>
						<div class="item-block">
							<div class="name">{{$t('mobile.refund.selectTitle1')}}</div>
							<div class="text">{{$t('mobile.refund.selectTip1')}}</div>
						</div>
						<div class="item-label">
							<i class="el-icon-arrow-right"></i>
						</div>
					</router-link>
					<router-link :to="{ name: 'mRefundsApply', params: $route.params }" class="item-row" replace>
						<div class="item-block">
							<div class="name">{{$t('mobile.refund.selectTitle2')}}</div>
							<div class="text">{{$t('mobile.refund.selectTip2')}}</div>
						</div>
						<div class="item-label">
							<i class="el-icon-arrow-right"></i>
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchRefundGoods } from "@/api/mobile/order"

export default {
	data() {
		return {
			goodsInfo: {}
		}
	},
	created () {
		this.getRefundGoods()
	},
	methods: {
		getRefundGoods() {
			fetchRefundGoods(this.$route.params).then(response => {
				this.goodsInfo = response.data
			}).catch(() => {
				this.$router.go(-1)
			})
		}
	}
}
</script>

<style lang="scss" scoped>
	.mhome-refund {
		padding: 0.384rem;
		.refund-section {
			overflow: hidden;
			border-radius: 0.256rem;
			padding: 0 0.384rem;
			background-color: #ffffff;
			margin-bottom: 0.32rem;
			.title {
				line-height: 0.544rem;
				font-size: 0.448rem;
				font-weight: 600;
				padding: 0.32rem 0;
			}
			.item-goods {
				display: flex;
				margin-bottom: 0.32rem;
				.item-pic {
					flex-shrink: 0;
					width: 2.56rem;
					height: 2.56rem;
					border-radius: 0.32rem;
					object-fit: cover;
				}
				.item-main {
					flex: 1;
					width: 0;
					margin: 0 0.32rem;
					.name {
						color: #333333;
						font-size: 0.416rem;
						max-height: 1.28rem;
						line-height: 0.64rem;
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						white-space: normal;
					}
					.desc {
						max-height: 1.28rem;
						line-height: 0.64rem;
						color: #999999;
						font-size: 0.416rem;
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						white-space: normal;
					}
				}
				.item-box {
					flex-shrink: 0;
					text-align: right;
					line-height: 0.64rem;
					.price {
						color: #333333;
						font-weight: 600;
						font-size: 0.384rem;
						span {
							font-size: 0.448rem;
						}
					}
					.number {
						color: #999999;
						font-size: 0.384rem;
					}
				}
			}
			.item-row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0.192rem 0;
				.item-block {
					.name {
						color: #333333;
						font-size: 0.416rem;
						font-weight: 600;
						height: 0.64rem;
						line-height: 0.64rem;
					}
					.text {
						height: 0.544rem;
						line-height: 0.544rem;
						color: #999999;
						white-space: nowrap;
						font-size: 0.352rem;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.item-label {
					color: #999999;
					font-size: 0.416rem;
				}
			}
		}
	}
</style>